#modal {
    position: fixed; /* Stay in place */
    z-index: 25; /* Sit on top */
    left: 0;
    top: 0px;

    width: 100vw; /* Full width */
    height: 100vh; /* Full height */
    overflow: hidden; /* Enable scroll if needed */
    text-align:center;
    padding-top:45px;
    background-color: rgba(0,0,0,1)
}

iframe {
    width: 100%;
    height:100%;
}

.wrapper {
    position: fixed;
    /* padding-bottom: 56.25%;
    padding-top: 35px; */
    height: 500px;
    right:0;
    bottom:0;
    left:0;
    top:0;
    -webkit-overflow-scrolling:touch;
    overflow-y: scroll;
    width:85%;
    margin:auto;
}

.close {
    color: white;
    text-decoration:underline;
    position:relative;
    float:right;
    top:2px;
    right:2%;
    margin:0;
    z-index:31;
}

.close:hover {
    cursor:pointer
}


@media only screen and (min-width:630px) and ( min-height:360px)  {
    .wrapper {
        padding-top: 140px;
    }

    .close {
        right: 7%;
    }
    iframe {
        height:61%;
        width: 87%;
    }
}

@media only screen and (min-width:730px) and ( min-height:300px)  {
    .wrapper {
        padding-top: 140px;
        top: 58px;
    }

    
    iframe {
        height:61%;
        width: 73%;
    }
}

@media only screen and (min-width:561px) and ( min-height:373px) {
    .wrapper {
        padding-top: 140px;
        top: 20px;
    }
    
    iframe {
        width:100%;
        height: 45%;
    }
}

@media only screen and (min-width:580px) and ( min-height:700px) {
    .wrapper {
        padding-top: 0;
    }
    
    iframe {
        height: 100%;
    }
}